
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import CreateUi from "@/components/Core/Create/CreateUI.vue";

import { IHydraulicSystemGroupService } from "@/services";

@Component({
  components: {
    CreateUi,
  },
})
export default class GroupCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenNameMonograms!: string[];

  get hydraulicSystemGroupService() {
    return this.container.resolve<IHydraulicSystemGroupService>(
      S.HYDRAULIC_SYSTEM_GROUP_SERVICE
    );
  }

  loading: boolean = false;
  name: string | null = null;
  monogram: string | null = null;
  nameTrigramRule: string | boolean = true;

  async create() {
    try {
      this.loading = true;
      const groupCreated = await this.hydraulicSystemGroupService.create({
        name: this.name!,
        monogram: this.monogram!,
      });
      this.$emit("create", groupCreated);
    } finally {
      this.loading = false;
    }
  }

  updateName(name: string) {
    this.name = name;
    this.triggerValidation();
  }

  updateMonogram(monogram: string) {
    this.monogram = monogram;
    this.triggerValidation();
  }

  triggerValidation() {
    if (this.monogram == null || this.name == null) {
      this.nameTrigramRule = true;
    } else {
      this.nameTrigramRule =
        _.findIndex(
          this.forbiddenNameMonograms,
          (s) =>
            s == this.name!.toUpperCase() + "-" + this.monogram!.toUpperCase()
        ) == -1
          ? true
          : "Le couple Nom - Monogramme est déjà existant";
    }
  }
}
